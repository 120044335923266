//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import AuthMessage from 'kolibri.coreVue.components.AuthMessage';
import partition from 'lodash/partition';
import { crossComponentTranslator } from 'kolibri.utils.i18n';
import { ComponentMap } from '../constants';
import AuthBase from './AuthBase';

export default {
  name: 'FacilitySelect',
  components: { AuthBase },
  mixins: [commonCoreStrings],
  props: {
    // This component is interstitial and needs to know where to go when it's done
    // The type is Object, but it needs to be one of the listed routes in the validator
    whereToNext: {
      type: Object,
      required: true,
      validate(obj) {
        return [ComponentMap.SIGN_IN, ComponentMap.SIGN_UP].includes(obj.name);
      },
    },
  },
  computed: {
    ...mapGetters(['facilities']),
    backTo() {
      return this.$router.getRoute(ComponentMap.AUTH_SELECT);
    },
    facilityList() {
      if (this.whereToNext.name === ComponentMap.SIGN_UP) {
        const partitionedFacilities = partition(
          this.facilities,
          f => f.dataset.learner_can_sign_up
        );
        return {
          enabled: partitionedFacilities[0],
          disabled: partitionedFacilities[1],
        };
      } else {
        return { enabled: this.facilities, disabled: [] };
      }
    },
    label() {
      return this.whereToNext.name === ComponentMap.SIGN_UP
        ? this.$tr('canSignUpForFacilityLabel')
        : this.$tr('selectFacilityLabel');
    },
    AuthMessageStrings() {
      return crossComponentTranslator(AuthMessage);
    },
  },
  methods: {
    setFacility(facilityId) {
      // Save the selected facility, get its config, then move along to next route
      this.$store.dispatch('setFacilityId', { facilityId }).then(() => {
        this.$store.dispatch('getFacilityConfig', facilityId).then(() => {
          this.$router.push(this.whereToNext);
        });
      });
    },
  },
  $trs: {
    canSignUpForFacilityLabel:
      'Select the facility that you want to associate your new account with:',
    askAdminForAccountLabel: 'Ask your administrator to create an account for these facilities:',
    selectFacilityLabel: 'Select the facility that has your account',
  },
};

