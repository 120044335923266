//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import { ComponentMap } from '../constants';
import AuthBase from './AuthBase';

export default {
  name: 'AuthSelect',
  components: { AuthBase },
  mixins: [commonCoreStrings],
  computed: {
    signUpRoute() {
      const whereToNext = this.$router.getRoute(ComponentMap.SIGN_UP);
      return { ...this.facilitySelectRoute, params: { whereToNext } };
    },
    signInRoute() {
      const whereToNext = this.$router.getRoute(ComponentMap.SIGN_IN);
      return { ...this.facilitySelectRoute, params: { whereToNext } };
    },
    facilitySelectRoute() {
      return this.$router.getRoute(ComponentMap.FACILITY_SELECT);
    },
  },
  $trs: {
    createAccountAction: 'Create an account',
    newUserPrompt: {
      message: 'Are you a new user?',
      context:
        'When a device has multiple facilities, the user is asked if they are a new user in association with a button that allows the user to create a new account',
    },
    signInPrompt: {
      message: 'Sign in if you have an existing account',
      context:
        'When a device has multiple facilities, this message is above a button which leads the user to the rest of the sign in process.',
    },
  },
};

