//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import CoreBase from 'kolibri.coreVue.components.CoreBase';
import { mapState, mapGetters } from 'vuex';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import PointsIcon from 'kolibri.coreVue.components.PointsIcon';
import PermissionsIcon from 'kolibri.coreVue.components.PermissionsIcon';
import UserTypeDisplay from 'kolibri.coreVue.components.UserTypeDisplay';
import { PermissionTypes } from 'kolibri.coreVue.vuex.constants';
import { FacilityUserResource } from 'kolibri.resources';
import GenderDisplayText from 'kolibri.coreVue.components.GenderDisplayText';
import BirthYearDisplayText from 'kolibri.coreVue.components.BirthYearDisplayText';
import { ComponentMap } from '../../constants';
import ChangeUserPasswordModal from './ChangeUserPasswordModal';

export default {
  name: 'ProfilePage',
  metaInfo() {
    return {
      title: this.$tr('documentTitle'),
    };
  },
  components: {
    BirthYearDisplayText,
    ChangeUserPasswordModal,
    CoreBase,
    GenderDisplayText,
    PermissionsIcon,
    PointsIcon,
    UserTypeDisplay,
  },
  mixins: [responsiveWindowMixin, commonCoreStrings],
  data() {
    return {
      facilityUser: {},
      showPasswordModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'facilityConfig',
      'getUserKind',
      'getUserPermissions',
      'isCoach',
      'isSuperuser',
      'totalPoints',
      'userHasPermissions',
    ]),
    ...mapState({
      session: state => state.core.session,
    }),
    profileEditRoute() {
      return this.$router.getRoute(ComponentMap.PROFILE_EDIT);
    },
    userPermissions() {
      return pickBy(this.getUserPermissions);
    },
    facilityName() {
      const match = find(this.$store.getters.facilities, {
        id: this.$store.getters.currentFacilityId,
      });
      return match ? match.name : '';
    },
    permissionType() {
      if (this.isSuperuser) {
        return PermissionTypes.SUPERUSER;
      } else if (this.userHasPermissions) {
        return PermissionTypes.LIMITED_PERMISSIONS;
      }
      return null;
    },
    permissionTypeText() {
      if (this.isSuperuser) {
        return this.$tr('isSuperuser');
      } else if (this.userHasPermissions) {
        return this.$tr('limitedPermissions');
      }
      return '';
    },
    canEditPassword() {
      const learner_can_edit =
        this.facilityConfig.learner_can_edit_password &&
        !this.facilityConfig.learner_can_login_with_no_password;
      return this.isSuperuser || this.isCoach || learner_can_edit;
    },
  },
  created() {
    this.$store.dispatch('fetchPoints');
  },
  mounted() {
    this.fetchFacilityUser();
  },
  methods: {
    getPermissionString(permission) {
      if (permission === 'can_manage_content') {
        return this.$tr('manageContent');
      }
      return permission;
    },
    fetchFacilityUser() {
      FacilityUserResource.fetchModel({ id: this.session.user_id }).then(facilityUser => {
        this.facilityUser = { ...facilityUser };
      });
    },
  },
  $trs: {
    detailsHeader: 'Details',
    editAction: 'Edit',
    isSuperuser: 'Super admin permissions ',
    manageContent: 'Manage content',
    manageDevicePermissions: 'Manage device permissions',
    points: 'Points',
    limitedPermissions: 'Limited permissions',
    youCan: 'You can:',
    changePasswordPrompt: 'Change password',
    documentTitle: 'User Profile',
  },
};

