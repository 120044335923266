//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapGetters, mapActions } from 'vuex';
import { FacilityUsernameResource } from 'kolibri.resources';
import get from 'lodash/get';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import { LoginErrors } from 'kolibri.coreVue.vuex.constants';
import PasswordTextbox from 'kolibri.coreVue.components.PasswordTextbox';
import { validateUsername } from 'kolibri.utils.validators';
import UiAutocompleteSuggestion from 'kolibri-design-system/lib/keen/UiAutocompleteSuggestion';
import UiAlert from 'kolibri-design-system/lib/keen/UiAlert';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import { ComponentMap } from '../../constants';
import getUrlParameter from '../getUrlParameter';
import AuthBase from '../AuthBase';
import UsersList from '../UsersList';
import SignInHeading from './SignInHeading';

const MAX_USERS_FOR_LISTING_VIEW = 16;

export default {
  name: 'SignInPage',
  metaInfo() {
    return {
      title: this.$tr('documentTitle'),
    };
  },
  components: {
    AuthBase,
    PasswordTextbox,
    SignInHeading,
    UiAutocompleteSuggestion,
    UiAlert,
    UsersList,
  },
  mixins: [responsiveWindowMixin, commonCoreStrings],
  data() {
    return {
      username: '',
      password: '',
      usernameSuggestions: [],
      usernamesForCurrentFacility: [],
      suggestionTerm: '',
      showDropdown: true,
      highlightedIndex: -1,
      usernameBlurred: false,
      passwordBlurred: false,
      formSubmitted: false,
      createdPassword: '',
      createdPasswordIsValid: false,
      busy: false,
      loginError: null,
      usernameSubmittedWithoutPassword: false,
    };
  },
  computed: {
    ...mapGetters(['selectedFacility', 'isAppContext']),
    ...mapState('signIn', ['hasMultipleFacilities']),
    backToFacilitySelectionRoute() {
      const facilityRoute = this.$router.getRoute(ComponentMap.FACILITY_SELECT);
      const whereToNext = this.$router.getRoute(ComponentMap.SIGN_IN);
      return { ...facilityRoute, params: { whereToNext } };
    },
    showPasswordForm() {
      return (
        Boolean(this.username) &&
        (this.passwordMissing || this.invalidCredentials || this.usernameSubmittedWithoutPassword)
      );
    },
    showUsernameForm() {
      return !this.showPasswordForm && !this.showUsersList;
    },
    passwordMissing() {
      return this.loginError === LoginErrors.PASSWORD_MISSING;
    },
    invalidCredentials() {
      return this.loginError === LoginErrors.INVALID_CREDENTIALS;
    },
    needsToCreatePassword() {
      return this.loginError === LoginErrors.PASSWORD_NOT_SPECIFIED;
    },
    simpleSignIn() {
      return this.selectedFacility.dataset.learner_can_login_with_no_password;
    },
    showUsersList() {
      return this.selectedFacility.num_users <= MAX_USERS_FOR_LISTING_VIEW && this.isAppContext;
    },
    suggestions() {
      // Filter suggestions on the client side so we don't hammer the server
      return this.usernameSuggestions.filter(sug =>
        sug.toLowerCase().startsWith(this.username.toLowerCase())
      );
    },
    usernameIsInvalidText() {
      if (this.usernameBlurred || this.formSubmitted) {
        if (this.username === '') {
          return this.coreString('requiredFieldError');
        } else if (!validateUsername(this.username)) {
          return this.coreString('usernameNotAlphaNumError');
        }
      }
      return '';
    },
    usernameIsInvalid() {
      return Boolean(this.usernameIsInvalidText);
    },
    passwordIsInvalidText() {
      if (this.passwordBlurred || this.formSubmitted) {
        if (this.simpleSignIn && this.password === '') {
          return this.$tr('requiredForCoachesAdmins');
        } else if (this.password === '') {
          return this.coreString('requiredFieldError');
        }
      }
      return '';
    },
    passwordIsInvalid() {
      return Boolean(this.passwordIsInvalidText);
    },
    nextParam() {
      // query is after hash
      if (this.$route.query.next) {
        return this.$route.query.next;
      }
      // query is before hash
      return getUrlParameter('next');
    },
    showFacilityName() {
      return (
        this.hasMultipleFacilities || get(this.selectedFacility, 'dataset.preset') !== 'informal'
      );
    },
  },
  watch: {
    username(newVal) {
      if (this.simpleSignIn && !this.showUsersList) {
        this.setSuggestionTerm(newVal);
      }
    },
    // Watch these computed properties and focus the fields
    // that need to be focused for cleaner transitions
    showPasswordForm(b) {
      if (b) {
        this.$nextTick(() => {
          this.$refs.password.focus();
        });
      }
    },
    showUsernameForm(b) {
      if (b) {
        this.$nextTick(() => this.$refs.username.focus());
        this.usernameSubmittedWithoutPassword = false;
      }
    },
  },
  created() {
    // Only fetch if we should fetch for this facility
    if (this.showUsersList) {
      FacilityUsernameResource.fetchCollection({
        getParams: {
          facility: this.selectedFacility.id,
        },
      }).then(data => {
        this.usernamesForCurrentFacility = data.map(u => u.username);
      });
    }
  },
  methods: {
    ...mapActions(['kolibriLogin', 'kolibriSetUnspecifiedPassword']),
    clearUser() {
      // Going back to the beginning - undo what we may have
      // changed so far and clearing the errors, if any
      this.username = '';
      this.password = '';
      this.createdPassword = '';
      // This ensures we don't get '<field> required' when going back
      // and forth
      this.usernameBlurred = false;
      this.passwordBlurred = false;
      this.loginError = null;
    },
    // Sets the selected list user and/or logs them in
    setSelectedUsername(username) {
      this.username = username;
      // Try to sign in now to validate the username
      // and to check if we even need a password
      // or need to change a password
      this.signIn();
    },
    updatePasswordAndSignIn() {
      if (this.createdPasswordIsValid) {
        this.busy = true;
        this.kolibriSetUnspecifiedPassword({
          username: this.username,
          password: this.createdPassword,
          facility: this.selectedFacility.id,
        }).then(() => {
          // Password successfully set
          // Use this password now to sign in
          this.password = this.createdPassword;
          this.signIn();
        });
      } else {
        this.$refs.createPassword.focus();
      }
    },
    setSuggestionTerm(newVal) {
      if (newVal !== null && typeof newVal !== 'undefined') {
        // Only check if defined or not null
        if (newVal.length < 3) {
          // Don't search for suggestions if less than 3 characters entered
          this.suggestionTerm = '';
          this.usernameSuggestions = [];
        } else if (
          (!newVal.startsWith(this.suggestionTerm) && this.suggestionTerm.length) ||
          !this.suggestionTerm.length
        ) {
          // We have already set a suggestion search term
          // The currently set suggestion term does not match the current username
          // Or we do not currently have a suggestion term set
          // Set it to the new term and fetch new suggestions
          this.suggestionTerm = newVal;
          this.setSuggestions();
        }
      }
    },
    setSuggestions() {
      FacilityUsernameResource.fetchCollection({
        getParams: {
          facility: this.selectedFacility.id,
          search: this.suggestionTerm,
        },
      })
        .then(users => {
          this.usernameSuggestions = users.map(user => user.username);
          this.showDropdown = true;
        })
        .catch(() => {
          this.usernameSuggestions = [];
        });
    },
    handleKeyboardNav(e) {
      switch (e.code) {
        case 'ArrowDown':
          if (this.showDropdown && this.suggestions.length) {
            this.highlightedIndex = Math.min(
              this.highlightedIndex + 1,
              this.suggestions.length - 1
            );
          }
          break;
        case 'ArrowUp':
          if (this.showDropdown && this.suggestions.length) {
            this.highlightedIndex = Math.max(this.highlightedIndex - 1, -1);
          }
          break;
        case 'Escape':
          this.showDropdown = false;
          break;
        case 'NumpadEnter':
        case 'Enter':
          if (this.highlightedIndex < 0) {
            this.showDropdown = false;
            this.signIn();
          } else {
            this.fillUsername(this.suggestions[this.highlightedIndex]);
            e.preventDefault();
          }
          break;
        default:
          this.showDropdown = true;
      }
    },
    fillUsername(username) {
      // Only do this if we have been passed a non-null value
      if (username !== null && typeof username !== 'undefined') {
        this.username = username;
        this.showDropdown = false;
        this.highlightedIndex = -1;
        // focus on input after selection
        this.$refs.username.focus();
      }
    },
    handlePasswordBlur() {
      setTimeout(() => (this.passwordBlurred = true), 200);
    },
    handleUsernameBlur() {
      this.usernameBlurred = true;
      // Unblur password to avoid inadvertent validation errors when
      // moving between username and password field views
      this.passwordBlurred = false;
      this.showDropdown = false;
    },
    signIn() {
      this.busy = true;

      const sessionPayload = {
        username: this.username,
        password: this.password,
        facility: this.selectedFacility.id,
      };

      if (this.nextParam) {
        sessionPayload['next'] = this.nextParam;
      }

      this.kolibriLogin(sessionPayload)
        .then(err => {
          // If we don't have a password, we submitted without a username
          if (err) {
            if (err === LoginErrors.PASSWORD_NOT_SPECIFIED) {
              // This error overrides the whole layout
              this.loginError = err;
            } else {
              // Otherwise, only show errors when we've submitted a password
              this.usernameSubmittedWithoutPassword = !this.password;
              this.loginError = this.usernameSubmittedWithoutPassword ? null : err;
            }
          }
          this.busy = false;
        })
        .catch(() => {
          this.busy = false;
        });
    },
    suggestionStyle(i) {
      return {
        backgroundColor: this.highlightedIndex === i ? this.$themePalette.grey.v_200 : '',
      };
    },
  },
  $trs: {
    // TODO: Remove the comments in $trs, run the linter, fix the issues
    // Disabling this altogether for now because we use some with crossComponentTranslator
    /* eslint-disable kolibri/vue-no-unused-translations */
    changeLabel: {
      message: 'Change',
      context:
        '(verb) Link to change the facility to sign in when the device has more than one facility',
    },
    signInToFacilityLabel: "Sign into '{facility}'",
    greetUser: 'Hi, {user}',
    signInError: 'Incorrect username or password',
    requiredForCoachesAdmins: 'Password is required for coaches and admins',
    documentTitle: 'User Sign In',
    needToMakeNewPasswordLabel: 'Hi, {user}. You need to set a new password for your account.',
    nextLabel: 'Next',
    signingInToFacilityAsUserLabel: "Signing in to '{facility}' as '{user}'",
    signingInAsUserLabel: "Signing in as '{user}'",
    changeUser: 'Change user',
    changeFacility: 'Change facility',
    multiFacilitySignInError: 'Incorrect username, password, or facility',
    /* eslint-enable */
  },
};

