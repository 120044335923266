//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import { crossComponentTranslator } from 'kolibri.utils.i18n';
import SignInPage from './index';

export default {
  name: 'SignInHeading',
  props: {
    showFacilityName: {
      type: Boolean,
      required: true,
    },
    showPasswordForm: {
      type: Boolean,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['selectedFacility']),
    strings() {
      // Gross
      return crossComponentTranslator(SignInPage);
    },
  },
};

