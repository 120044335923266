//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';
import CoreLogo from 'kolibri.coreVue.components.CoreLogo';
import PrivacyInfoModal from 'kolibri.coreVue.components.PrivacyInfoModal';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import branding from 'kolibri.utils.branding';
import loginComponents from 'kolibri.utils.loginComponents';
import urls from 'kolibri.urls';
import { crossComponentTranslator } from 'kolibri.utils.i18n';
import { ComponentMap } from '../constants';
import LanguageSwitcherFooter from '../views/LanguageSwitcherFooter';
import AuthSelect from './AuthSelect';
import getUrlParameter from './getUrlParameter';
import plugin_data from 'plugin_data';

export default {
  name: 'AuthBase',
  components: { CoreLogo, LanguageSwitcherFooter, PrivacyInfoModal },
  mixins: [commonCoreStrings],
  props: {
    hideCreateAccount: {
      type: Boolean,
      required: false,
      default: false,
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      privacyModalVisible: false,
      whatsThisModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(['facilityConfig']),
    AuthSelectStrings() {
      return crossComponentTranslator(AuthSelect);
    },
    backgroundImageStyle() {
      if (this.$kolibriBranding.signIn.background) {
        const scrimOpacity =
          this.$kolibriBranding.signIn.scrimOpacity !== undefined
            ? this.$kolibriBranding.signIn.scrimOpacity
            : 0.7;
        return {
          backgroundColor: this.$themeTokens.primary,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, ${scrimOpacity}), rgba(0, 0, 0, ${scrimOpacity})), url(${this.$kolibriBranding.signIn.background})`,
        };
      }
      return { backgroundColor: this.$themeBrand.primary.v_900 };
    },
    guestURL() {
      return urls['kolibri:core:guest']();
    },
    canSignUp() {
      return this.facilityConfig.learner_can_sign_up;
    },
    nextParam() {
      // query is after hash
      if (this.$route.query.next) {
        return this.$route.query.next;
      }
      // query is before hash
      return getUrlParameter('next');
    },
    signUpPage() {
      const signUpRoute = this.$router.getRoute(ComponentMap.SIGN_UP);
      if (this.nextParam) {
        return { ...signUpRoute, query: { next: this.nextParam } };
      }
      return signUpRoute;
    },
    loginOptions() {
      // POC, in the future sorting of different login options can be implemented
      return [...loginComponents];
    },
    logoText() {
      return this.$kolibriBranding.signIn.title
        ? this.$kolibriBranding.signIn.title
        : this.coreString('kolibriLabel');
    },
    oidcProviderFlow() {
      return plugin_data.oidcProviderEnabled && this.nextParam;
    },
    showGuestAccess() {
      return plugin_data.allowGuestAccess && !this.oidcProviderFlow;
    },
    versionMsg() {
      return this.$tr('poweredBy', { version: __version });
    },
  },
  created() {
    this.$kolibriBranding = branding;
  },
  $trs: {
    accessAsGuest: 'Explore without account',
    // This is in the eslint-disabling section because we crossComponentTranslator @ AuthSelect
    // eslint-disable-next-line kolibri/vue-no-unused-translations
    createAccountAction: 'Create an account',
    oidcGenericExplanation:
      'Kolibri is an e-learning platform. You can also use your Kolibri account to log in to some third-party applications.',
    // Disable the rule here because we will keep this unused string in case we need it later on
    // eslint-disable-next-line kolibri/vue-no-unused-translations
    oidcSpecificExplanation:
      "You were sent here from the application '{app_name}'. Kolibri is an e-learning platform, and you can also use your Kolibri account to access '{app_name}'.",
    poweredBy: 'Kolibri {version}',
    poweredByKolibri: 'Powered by Kolibri',
    whatsThis: "What's this?",
    restrictedAccess: {
      message: 'Access to Kolibri has been restricted for external devices',
      context: 'Error message description',
    },
    restrictedAccessDescription: {
      message:
        'To change this, sign in as a super admin and update the Device network access settings',
      context: 'Error message description',
    },
  },
};

